
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React from 'react'

import ApplyForAccount from 'components/modules/Login/ApplyForAccount'
import LoginContent from 'components/modules/Login/LoginContent'
import withPageStaticProps from 'utils/next/withPageStaticProps'

/**
 * Used for E2E tests.
 */
const TAG = 'LoginPage'

const LoginPage: NextPageWithLayout = () => (
  <>
    <LoginContent />
    <ApplyForAccount />
  </>
)

LoginPage.displayName = TAG

export default LoginPage

 const _getStaticProps = withPageStaticProps()


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/login/index.page',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  