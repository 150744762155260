import type { Category } from '@commercetools/platform-sdk'

import { ADMIN_CLIENT } from 'commercetools/client/admin'

const CATEGORIES_LIMIT = 500

/**
 * @returns a list of categories
 * @link https://docs.commercetools.com/api/projects/categories#query-categories
 */
async function getAllCategories(): Promise<Category[]> {
  const respose = await ADMIN_CLIENT.categories()
    .get({ queryArgs: { limit: CATEGORIES_LIMIT } })
    .execute()

  if (respose.body.count > CATEGORIES_LIMIT) {
    throw new Error(`Categories limit exceeded. Please increase the limit to ${respose.body.count}`)
  }

  return respose.body.results
}

export default getAllCategories
