import pick from 'lodash/pick'
import type { GetStaticProps, GetStaticPropsContext, GetStaticPropsResult } from 'next'
import type { ParsedUrlQuery } from 'querystring'

import getAllCategories from 'commercetools/categories/getAllCategories'
import getRevalidate from 'config/revalidate'
import hasProperty from 'utils/hasProperty'

type GetStaticPropsEnhanced<QUERY extends ParsedUrlQuery, PROPS extends EmptyObject> = (
  context: GetStaticPropsContext<QUERY>,
  sharedPageStaticProps: SharedPageStaticProps,
) => Promise<GetStaticPropsResult<PROPS>> | GetStaticPropsResult<PROPS>

export interface WithPageStaticPropsOptions {
  /**
   * @default false
   */
  isErrorPage?: boolean
}

/**
 * @returns shared props for all pages.
 * @see {@link SharedPageStaticProps} for more information.
 */
async function getSharedProps(_: string | undefined, __: boolean): Promise<SharedPageStaticProps> {
  const allCategories = await getAllCategories()
  const categoryKeys = ['id', 'version', 'name', 'slug', 'ancestors', 'key']
  const categories =
    (allCategories.map((category) => pick(category, ...categoryKeys)) as GlobalProps['categories']) ?? []

  return {
    globalProps: {
      categories,
      BUILD_DATE: process.env.NEXT_PUBLIC_BUILD_DATE || '',
      RESPONSE_DATE: new Date().toISOString(),
    },
  }
}

/**
 * @returns static props with shared props using passed `getStaticProps` function.
 */
async function staticPropsWithFunction<
  PROPS extends EmptyObject = EmptyObject,
  QUERY extends ParsedUrlQuery = SlugQueryArray,
>(
  getStaticProps: GetStaticPropsEnhanced<QUERY, PROPS>,
  context: GetStaticPropsContext<QUERY>,
  shared: SharedPageStaticProps,
) {
  const result = await getStaticProps(context, shared)
  if (hasProperty(result, 'props') && result.props) {
    result.props = {
      ...result.props,
      ...shared,
    }
  }
  return result as GetStaticPropsResult<SharedPageStaticProps & PROPS>
}

/**
 * HoC for `getStaticProps` to share common props across pages.
 *
 * **Each page should export this function as `getStaticProps`.**
 *
 * More information:
 * @link https://github.com/vercel/next.js/discussions/10949#discussioncomment-2706819
 */
function withPageStaticProps<PROPS extends EmptyObject = EmptyObject, QUERY extends ParsedUrlQuery = SlugQueryArray>(
  getStaticProps?: GetStaticPropsEnhanced<QUERY, PROPS>,
  options?: WithPageStaticPropsOptions,
): GetStaticProps<SharedPageStaticProps & PROPS, QUERY> {
  return async (
    context: GetStaticPropsContext<QUERY>,
  ): Promise<GetStaticPropsResult<SharedPageStaticProps & PROPS>> => {
    const { locale, preview = false } = context

    // For error pages, we cannot return { notFound: true }.
    if (locale === 'default' && !options?.isErrorPage) {
      return { notFound: true }
    }

    const shared: SharedPageStaticProps = await getSharedProps(locale, preview)

    if (typeof getStaticProps === 'function') {
      return await staticPropsWithFunction<PROPS, QUERY>(getStaticProps, context, shared)
    }

    return {
      props: {
        ...(shared as SharedPageStaticProps & PROPS),
        layout: { props: {} },
      },
      revalidate: getRevalidate(),
    }
  }
}

export default withPageStaticProps
