import { createApiBuilderFromCtpClient } from '@commercetools/platform-sdk'
import type { ByProjectKeyRequestBuilder as ApiRoot } from '@commercetools/platform-sdk/dist/declarations/src/generated/client/by-project-key-request-builder'
import { AuthMiddlewareOptions, ClientBuilder, HttpMiddlewareOptions } from '@commercetools/sdk-client-v2'

/**
 * Use only in SSR or API routes
 * **This client has access to manage the entire site!!!**
 * @example
 * ```tsx
 * // SSR
 * export const getServerSideProps: GetServerSideProps = async (context) => {
 *
 *   const client = createCustomerAdminAPIClient()
 *   const businessUnits = await client.businessUnits().get().execute()
 *
 *   return {
 *    props: { businessUnits },
 *   }
 * }
 * ```
 */
export function createCustomerAdminAPIClient(): ApiRoot {
  const authMiddlewareOptions: AuthMiddlewareOptions = {
    credentials: {
      clientId: process.env.CTP_CLIENT_ID,
      clientSecret: process.env.CTP_CLIENT_SECRET,
    },
    host: process.env.CTP_AUTH_HOST,
    projectKey: process.env.CTP_PROJECT_KEY,
    fetch,
  }

  const httpMiddlewareOptions: HttpMiddlewareOptions = {
    host: process.env.CTP_API_URL,
    fetch,
  }

  return createApiBuilderFromCtpClient(
    new ClientBuilder()
      .withClientCredentialsFlow(authMiddlewareOptions)
      .withHttpMiddleware(httpMiddlewareOptions)
      .build(),
  ).withProjectKey({ projectKey: process.env.CTP_PROJECT_KEY })
}

/**
 * Use only on Server side.
 * **That client only work on SSR or API routes and has access to manage the entire site!!!**
 *
 * @example
 * ```tsx
 *   ADMIN_CLIENT.businessUnits().get().execute()).then((res) => {
 *     console.log(res.body)
 *   })
 * ```
 */
export const ADMIN_CLIENT = createCustomerAdminAPIClient()
