const DEFAULT_REVALIDATE_TIME = 900 // 15 minutes

/**
 * We created function to get revalidate time from environment variable.
 *
 * This is a future proof feature so we could change the revalidate time
 * for the page depending on the page path from the arguments.
 *
 * @returns {number} - The revalidate time in seconds
 *
 * @default
 * @see {@link DEFAULT_REVALIDATE_TIME}
 */
function getRevalidate(): number {
  const fromEnv = +process.env.NEXT_PUBLIC_REVALIDATE_TIME
  return fromEnv ? fromEnv : DEFAULT_REVALIDATE_TIME
}

export default getRevalidate
