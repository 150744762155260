import { useMsal } from '@azure/msal-react'
import { Button, Heading, SystemStyleInterpolation } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import React, { FunctionComponent, useCallback } from 'react'

import HeroLogin from 'components/modules/Hero/HeroLogin'
import { loginRequest } from 'config/auth'
import useRedirectTo from 'hooks/useRedirectTo'
/**
 * Used for E2E tests.
 */
const TAG = 'LoginContent'

const styles = {
  title: {
    size: '2xl',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: '400',
  },
  signIn: {
    mt: '10',
    textTransform: 'uppercase',
    color: 'primary.white',
    backgroundColor: 'primary.blue',
    w: 'side-block',
    maxW: 'full',
    _hover: {
      backgroundColor: 'primary.white',
      color: 'primary.black',
    },
    _focusVisible: {
      backgroundColor: 'primary.white',
      color: 'primary.black',
    },
  },
} as const satisfies SystemStyleInterpolation

const b2cPasswordResetCancelErrorCode = 'AADB2C90091' // error_description: 'AADB2C90091: The user has cancelled entering self-asserted information.\r\n'
const b2cProblemProcessingRequestErrorCode = 'AADB2C90075'

const LoginContent: FunctionComponent = () => {
  const { instance } = useMsal()
  const { t } = useTranslation('common')
  const redirectTo = useRedirectTo()

  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#interaction_in_progress
  const signIn = useCallback<VoidFunction>(async () => {
    instance
      .handleRedirectPromise()
      .then((tokenResponse) => {
        if (!tokenResponse) {
          const accounts = instance.getAllAccounts()
          // No user signed in
          if (accounts.length === 0) {
            instance.loginRedirect({ ...loginRequest, redirectStartPage: redirectTo })
          }
        }
      })
      .catch((err) => {
        if (
          err.errorMessage.indexOf(b2cPasswordResetCancelErrorCode) > -1 ||
          err.errorMessage.indexOf(b2cProblemProcessingRequestErrorCode) > -1
        ) {
          instance.loginRedirect(loginRequest)
        }
      })
  }, [instance, redirectTo])

  return (
    <HeroLogin>
      <Heading as="h1" {...styles.title}>
        {t('pages.login.title')}
      </Heading>

      <Button variant="secondary" size="lg" onClick={signIn} {...styles.signIn}>
        {t('pages.login.signIn')}
      </Button>
    </HeroLogin>
  )
}

LoginContent.displayName = TAG

export default LoginContent
